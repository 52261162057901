<template>
  <page-layout>
    <div id="orderPay">
      <div class="up">
        <div class="up_left">
          <div class="icon_box">
            <a-icon type="check-circle" theme="filled" class="green" />
          </div>
          <div class="title_box">
            <div class="title1">订单提交成功！去付款咯～</div>
            <div class="title2">
              请在
              <span style="font-size: 14px; font-weight: bold">
                {{ info.endOrderCancelTime }}
              </span>
              前完成支付, 超时后订单将取消
            </div>
            <div class="title3">
              收货信息：{{ info.realName }} {{ info.userPhone }}
              {{ info.userAddress }}
            </div>
          </div>
        </div>
        <div>
          <div class="gray font12">
            订单金额：<span class="origin"
              >¥{{ info.totalPrice || "0.00" | price }}元
            </span>
          </div>
          <div class="gray font12 mart5">
            运费合计：<span class="origin">
              ¥{{ info.payPostage || "0.00" | price }}元
            </span>
          </div>
          <div class="gray font14 mart5 yingfu">
            应付金额：<span class="origin">
              ¥{{ info.payPrice || "0.00" | price }}元
            </span>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title">选择支付方式</div>
        <div class="pay_type">
          <div
            class="type"
            v-for="(item, index) in payTypeList"
            :key="index"
            :class="active == item.payType ? 'active' : ''"
            @click="active = item.payType"
          >
            <div>
              <div class="blod font14">
                {{ item.name }}
                <span class="block">{{
                  item.type == 1 ? "线上" : "线下"
                }}</span>
              </div>
              <div class="font12 mart5">{{ item.content }}</div>
            </div>
            <a-icon type="check-circle" theme="filled" />
          </div>
          <!-- <div
            class="type"
            :class="active == 2 ? 'active' : ''"
            @click="active = 2"
          >
            <div>
              <div class="blod font14">
                线下支付 <span class="block">线下</span>
              </div>
              <div class="font12 mart5">请上传线下汇款凭证</div>
            </div>
            <a-icon type="check-circle" theme="filled" />
          </div> -->
        </div>
      </div>

      <div class="box" v-if="active == 0 || active == 2">
        <div class="title mb16">请完善支付信息</div>
        <a-form-model :model="payForm" v-bind="formItemLayout">
          <a-form-model-item label="我的账户余额">
            ¥{{ $store.state.user.userInfo.now_money | price }}元
          </a-form-model-item>
          <a-form-model-item label="订单金额">
            ¥{{ info.payPrice | price }}元
          </a-form-model-item>
          <a-form-model-item label="支付密码">
            <a-input-password
              v-model="cipher"
              placeholder="请输入您的支付密码"
              autocomplete="new-password"
            />
          </a-form-model-item>
        </a-form-model>
        <a-row>
          <a-col :span="formItemLayout.labelCol.span" />
          <a-col :span="formItemLayout.wrapperCol.span">
            <a-button
              type="primary"
              :disabled="
                ($store.state.user.userInfo.now_money == 0 &&
                  (active == 0 || active == 2)) ||
                ($store.state.user.userInfo.now_money < info.payPrice &&
                  $store.state.user.userInfo.now_money > 0 &&
                  active == 0)
              "
              @click="payNow"
            >
              立即支付
            </a-button>
            <span
              style="margin-left: 16px; color: red; white-space: nowrap"
              v-if="
                $store.state.user.userInfo.now_money < info.payPrice &&
                $store.state.user.userInfo.now_money > 0 &&
                active == 2
              "
            >
              账户余额不足，不足部分将通过网银支付
            </span>
            <span
              style="margin-left: 16px; color: red; white-space: nowrap"
              v-if="
                $store.state.user.userInfo.now_money == 0 &&
                (active == 0 || active == 2)
              "
            >
              账户余额为0，请选择其他支付方式
            </span>
            <span
              style="margin-left: 16px; color: red; white-space: nowrap"
              v-if="
                $store.state.user.userInfo.now_money < info.payPrice &&
                $store.state.user.userInfo.now_money > 0 &&
                active == 0
              "
            >
              账户余额不足，请选择其他支付方式
            </span>
          </a-col>
        </a-row>
      </div>
      <div class="box" v-if="active == 1">
        <div class="title">选择支付银行(支持银行借记卡以及信用卡)</div>
        <div class="mart10" style="color: red">点击以下进行支付:</div>
        <div class="mart10">
          <a-row type="flex" align="middle">
            <a-col
              :span="6"
              style="
                width: 162px;
                height: 77px;
                padding: 20px 22px;
                border: 1px solid #eee;
                cursor: pointer;
              "
              @click="paying"
            >
              <img
                src="@/assets/img/back.png"
                style="width: 100%; height: 100%"
              />
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="box" v-if="active == 3">
        <div class="title mb16">请完善支付信息</div>
        <a-form-model :model="form" v-bind="formItemLayout">
          <a-form-model-item label="收款账户">
            <a-select
              v-model="form.bank_name"
              @change="handleChange"
              placeholder="请选择打款账户"
            >
              <a-select-opt-group
                v-for="(item, index) in accountList"
                :key="index"
              >
                <span slot="label">{{ item.bank_name }}：</span>
                <a-select-option :value="item.bank_name + '|' + item.bank_no">
                  {{ item.bank_no }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="订单金额">
            ¥{{ info.payPrice | price }}元
          </a-form-model-item>
          <a-form-model-item label="转账日期">
            <a-date-picker
              @change="changezDate"
              :disabled-date="disabledDate"
            />
          </a-form-model-item>
          <a-form-model-item label="付款凭证上传">
            <a-upload
              class="avatar-uploader"
              name="image"
              listType="picture-card"
              :showUploadList="false"
              :customRequest="customRequest"
              v-if="certificate_type == 1"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                alt="avatar"
                style="width: 90px; height: 90px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
        <a-row>
          <a-col :span="formItemLayout.labelCol.span"></a-col>
          <a-col :span="formItemLayout.wrapperCol.span">
            <a-button type="primary" @click="submitPay"> 提交审核 </a-button>
          </a-col>
        </a-row>

        <!-- <div class="mart10">
          <div>收款信息：</div>
          <div class="font12 mart10">{{ payInfo[0] }}</div>
          <div class="font12 mart5">{{ payInfo[1] }}</div>
          <div class="font12 mart5">{{ payInfo[2] }}</div>
        </div>
        <div class="mart10">
          <a-radio-group name="radioGroup" v-model="certificate_type">
            <a-radio :value="1">现在上传</a-radio>
            <a-radio :value="2">稍后上传</a-radio>
          </a-radio-group>
        </div>
        <div class="upload">
          <a-upload class="avatar-uploader" name="image" listType="picture-card" :showUploadList="false"
            :customRequest="customRequest" v-if="certificate_type == 1">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 90px; height: 90px" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <div class="tip" :class="certificate_type == 2 ? 'tip1' : ''">
            <span class="red">*</span>
            <span v-if="certificate_type == 1">如选择线下付款，请上传付款凭证，审核通过后完成订单付款。</span>
            <span v-if="certificate_type == 2">您可以稍后在“订单列表--待付款订单”上传付款凭证。</span>
          </div>
        </div>
        <a-button type="primary" class="submit" @click="submit">提交</a-button> -->
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import {
  orderPay,
  orderDetail,
  payOrder,
  uploadImg,
  getBankAll,
  offlinePayV2,
  yuePay,
} from "@/api/myOrder";
import { setting } from "@/api/login";
import { price } from "@/utils/filters";
import moment from "moment";

var orderTime = null;
var newWindow = null;

export default {
  name: "orderPay",
  components: { PageLayout },
  filters: { price },
  data() {
    return {
      active: -1,
      certificate_type: 1,
      imageUrl: "",
      loading: false,
      orderNum: "",
      info: {},
      payInfo: [],
      baseImageUrl: "",
      endTime: "",
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 8 },
      },
      form: {},
      accountList: [],
      payForm: {},
      cipher: "",
      disabledDate(current) {
        return current && current >= moment().endOf("day");
      },
    };
  },
  computed: {
    payTypeList() {
      const a = {
        name: "账户余额支付",
        type: 1, //1 线上   2线下
        content: "账户余额支付方式付款",
        payType: 0,
      };
      const b = {
        name: "网银支付",
        type: 1, //1 线上   2线下
        content: "选择以下支付方式付款",
        payType: 1,
      };
      const c = {
        name: "账户余额+网银支付",
        type: 1, //1 线上   2线下
        content: "混合支付方式付款",
        payType: 2,
      };
      return this.info.isPayBalance == 1 ? [a, b, c] : [b];
    },
  },
  watch: {
    active(val) {
      if (val == 3) {
        this.getAccountList();
      }
    },
  },
  mounted() {
    this.getData();
    setting().then((res) => {
      this.payInfo = res.data.data.setting.payBankInfo;
    });
  },
  created() {
    window.addEventListener("beforeunload", this.onBeforeunload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.onBeforeunload);
  },
  methods: {
    moment,
    onBeforeunload() {
      if (newWindow) {
        newWindow.close();
      }
    },
    getData() {
      this.orderNum = this.$route.query.orderNum;
      orderDetail(this.orderNum).then((res) => {
        this.info = res.data.data;
        this.info.userPhone = this.info.userPhone.replace(
          this.info.userPhone.substring(3, 7),
          "****"
        );
      });
    },
    submitPay() {
      let form = Object.assign({}, this.form);
      form = {
        bankName: form.bank_name.split("|")[0],
        bankNo: form.bank_name.split("|")[1],
        transferTime: form.transferTime,
        certificateImage: this.imageUrl,
      };
      console.log(this.orderNum, form);
      offlinePayV2(this.orderNum, form).then(() => {
        this.$message.success("提交成功");
        this.$router.replace("/bookBuy/order/myOrder");
      });
    },
    changezDate(date, dateString) {
      this.form.transferTime = dateString;
    },
    getAccountList() {
      getBankAll().then((res) => {
        this.accountList = res.data.data;
      });
    },
    changeDate(time) {
      time = time * 1000;
      orderTime = setInterval(() => {
        let newTime = +new Date();
        if (time - newTime > 0) {
          let sTime = parseInt((time - newTime) / 1000);
          let hours = this.addZero(Math.floor(sTime / 60 / 60));
          let min = this.addZero(Math.floor(sTime / 60));
          let sed = this.addZero(Math.floor(sTime % 60));
          this.endTime = hours + ":" + min + ":" + sed;
        } else {
          clearInterval(orderTime);
          this.endTime = "00:00:00";
        }
      }, 1000);
    },
    addZero(val) {
      return val < 10 ? "0" + val : val;
    },
    warning() {
      let that = this;
      this.$warning({
        title: "提示",
        okText: "确定",
        content:
          that.certificate_type == 2
            ? "您的订单已提交，请尽快上传付款凭证！"
            : "线下支付订单提交成功！我们将在24个小时内完成审核，您可在订单列表查看该订单支付状态。",
        onOk: function () {
          console.log("点击了确定");
          that.$router.push({
            path: "myOrder",
          });
        },
        onCancel: function () {
          console.log("跳转");
          that.$router.push({
            path: "myOrder",
          });
        },
      });
    },
    submit() {
      if (this.certificate_type == 1) {
        if (this.imageUrl == "") {
          this.$message.warning("请上传付款凭证");
          return;
        }
      }
      orderPay(this.orderNum, {
        certificate_type: this.certificate_type,
        certificate_image: this.imageUrl,
      }).then(() => {
        this.warning();
      });
    },
    payNow() {
      if (!this.cipher) {
        this.$message.warning("请输入支付密码");
        return;
      }
      const hide = this.$message.loading("支付中..", 0);
      yuePay(this.orderNum, {
        cipher: this.cipher,
        type: this.active == 2 ? 2 : 1,
      })
        .then((res) => {
          if (res.data.data.type == "yue") {
            this.$message.success("支付成功");
            this.$router.replace("/bookBuy/order/myOrder");
          } else if (res.data.data.type == "pay") {
            this.onGoInlinePay(res.data.data.url && res.data.data.url.sytUrl);
          }
        })
        .finally(() => {
          hide();
        });
    },
    paying() {
      newWindow = window.open("");
      payOrder(this.orderNum)
        .then((res) => {
          this.onGoInlinePay(res.data.data.sytUrl);
        })
        .catch(() => {
          newWindow.close();
          newWindow = null;
        });
    },
    onGoInlinePay(url) {
      if (!url) {
        newWindow.close();
        newWindow = null;
        return;
      }
      // newWindow = window.open(url, "_blank");
      newWindow.location.href = url;
      this.$info({
        title: "支付提示",
        content: "请在新标签页中进行支付",
        okText: "我已支付",
        showCancel: false,
        keyboard: false,
        maskClosable: false,
        closable: false,
        onOk: () => {
          this.$store.dispatch("getUserInfo");
          this.$router.replace("/bookBuy/order/myOrder");
          newWindow.close();
          newWindow = null;
        },
      });
    },
    handleOk() {},
    handleCancel() {
      // this.visible = false;
    },
    beforeUpload(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(res) {
      console.log(res);
    },
    customRequest({ file }) {
      new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          this.baseImageUrl = fileReader.result;
          this.upLoadImgs();
        };
      });
    },
    upLoadImgs() {
      uploadImg({ file: this.baseImageUrl }).then((res) => {
        this.imageUrl = res.data.data.url;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "orderPay";
</style>
